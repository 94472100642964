/*
  title color: #458ae1
  button background color: #458ae1
*/

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.43;
  color: #333;
}

/* UTILS */

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.mt {
  margin-top: 15px;
}

.mt--lg {
  margin-top: 30px;
}

.mt--sm {
  margin-top: 7.5px;
}

.mt--xl {
  margin-top: 45px;
}

.mt-0 {
  margin-top: 0;
}

.mb {
  margin-bottom: 15px;
}

.mb--sm {
  margin-bottom: 7.5px;
}

.mb--lg {
  margin-bottom: 30px;
}

.mb--xl {
  margin-bottom: 45px;
}

.ml {
  margin-left: 15px;
}

.ml--sm {
  margin-left: 7.5px;
}

.ml--lg {
  margin-left: 30px;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.top-right {
  top: 0;
  right: 0;
}

.w100 {
  width: 100%;
}

.input-longer {
  min-width: 300px;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-primary {
  color: #fff;
  background-color: #458ae1;
  border-color: #458ae1;
}

.btn-primary:hover {
  color: #fff;
  background-color: #226fd1;
  border-color: #1e64bb;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #226fd1;
  border-color: #1e64bb;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-pdf {
  width: 20px;
  height: 20px;
  padding: 0;
}

.btn-delete-item {
  width: 24px;
  height: 24px;
  padding: 0;
  color: #a2a2a2;
  border: 0;
}

.btn-icon-add {
  display: flex;
  align-items: center;
  padding: 5px;
}

.borderless {
  border: 0 !important;
}

.backgroundless {
  background: none;
}

.bg-grey {
  background-color: #d6dde3;
}

.font-semibold {
  font-weight: 600;
}

h1,
h2 {
  color: #458ae1;
}

/* END UTILS */

.container {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.container--main {
  padding-top: 20px;
  padding-bottom: 30px;
}

.form-group label {
  display: block;
}

.form-group-inline label {
  margin-right: 10px;
}

.section-objects .btn-delete-item {
  position: absolute;
  top: 15px;
  right: 5px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #d6dde3;
}

.table th {
  padding-top: 3px;
  padding-right: 5px;
  padding-bottom: 3px;
  padding-left: 5px;
  font-size: 14px;
}

.td--input {
  height: 24px;
  padding: 0;
}

.td--input input[type='text'],
.td--input input[type='number'] {
  display: block;
  width: 100%;
  height: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  border: 0;
}

.table_btn-add {
  min-width: 100px;
}

.table__col-delete {
  width: 30px;
  text-align: center;
}

.table__col-delete .btn-delete-item {
  padding: 0;
  vertical-align: middle;
}

.table-collateral tfoot td {
  padding-top: 7px;
  padding-bottom: 7px;
}

.tabs-container {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.tab-list {
  flex-wrap: wrap;
}

.tab-list button,
.tab-list button[data-selected] {
  border: 0;
}

.tab-list button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}

.tab-list button svg {
  margin-left: 3px;
}

.tab-list [data-selected] {
  color: #fff;
  background-color: #458ae1;
}

.icon-done {
  color: #458ae1;
  font-weight: 600;
}

.tab-list [data-selected] .icon-done {
  color: #fff;
}

.title-main {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}

/* .dscr-table {
} */

.dscr-table__col {
  flex: 1;
  padding-right: 10px;
}

.dscr-table__col--label {
  flex-basis: 175px;
  flex-shrink: 0;
}

.dscr-table__col--year {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dscr-table__row {
  display: flex;
  align-items: center;
  min-height: 30px;
}

.dscr-table__row--highlight {
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
}

.dscr-table__row--header {
  font-weight: 700;
  text-align: center;
}

.dscr-formula {
  font-family: 'Lucida Console', Monaco, monospace;
  font-size: 12px;
  font-weight: 700;
  padding-right: 15px;
}

.dscr-formula--main {
  font-size: 14px;
  text-align: center;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #8a8a8a;
}
