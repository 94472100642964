.notification {
  position: fixed;
  z-index: 1000;
  top: 15px;
  right: 50%;
  width: 600px;
  margin-right: -300px;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
}

.notification__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  padding: 0;
  font-size: 22px;
  background: none;
  border: 0;
}

.notification-success {
  color: #155724;
  background-color: rgba(212, 237, 218, 0.99);
  border-color: #c3e6cb;
}

.notification-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.notification-error {
  color: #721c24;
  background-color: rgba(248, 215, 218, 0.99);
  border-color: #f5c6cb;
}
